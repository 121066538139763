/**
 * modules, libraries, Angular
 */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { ShareButtonsModule } from 'ngx-sharebuttons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatExpansionModule } from '@angular/material/expansion';

// for AoT support, https://github.com/ocombe/"@ngx-translate/core"#aot
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SimpleNotificationsModule } from 'angular2-notifications';

import { RecaptchaModule } from 'ng-recaptcha';
import { MatTooltipModule } from '@angular/material/tooltip';
import { QuillModule, QUILL_CONFIG_TOKEN } from 'ngx-quill';

/**
 * guards
 */
import {
  CanActivateViaAuthGuard,
  CanActivateViaSuperAdminGuard,
  CanActivateViaAlreadyLoggedInGuard,
} from './common/auth.guard';

/**
 * components
 */
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CarouselComponent } from './home/carousel/carousel.component';
import { AboutUsComponent } from './aboutUs/about-us.component';
import { IntroComponent } from './intro/intro.component';
import { ResponseProfileComponent } from './searchResults/responseProfile/response-profile.component';
import { OrgBackgroundComponent } from './searchResults/orgBackground/org-background.component';
import { OrgLocationComponent } from './searchResults/orgLocation/org-location.component';
import { OrgDisasterComponent } from './searchResults/orgDisaster/org-disaster.component';
import { DisasterAboutComponent } from './searchResults/disasterAbout/disaster-about.component';
import { DapDonationComponent } from './searchResults/dapDonation/dap-donation.component';
import { GiveLivelyDonationComponent } from './searchResults/dapDonation/givelively-donation.component';
import { DapDonationThanksComponent } from './searchResults/dapDonationThanks/dap-donation-thanks.component';
import { FooterComponent } from './footer/footer.component';
import { VolunteersPageComponent } from './volunteersPage/volunteersPage.component';
import { DisastersPageComponent } from './home/disastersPage/disastersPage.component';
import { PrivacyPolicyPageComponent } from './privacyPage/privacyPage.component';
import { HeaderComponent } from './header/header.component';
import { PickerMenuComponent } from './header/pickerMenu/picker-menu.component';
import { OptionPickerComponent } from './header/optionPicker/option-picker.component';
import { CurrencyPickerComponent } from './header/currencyPicker/currency-picker.component';
import { LanguagePickerComponent } from './header/languagePicker/language-picker.component';
import { MarketingComponent } from './home/marketing/marketing.component';
import { SocialNetworkComponent } from './socialNetwork/socialNetwork.component';
import { SearchComponent } from './home/search/search.component';
import { DisasterListHomeComponent } from './home/disasterList/disasterList.component';
import { ReportModalComponent } from './searchResults/reportModal/report-modal.component';
import { LocationAboutComponent } from './searchResults/locationAbout/location-about.component';
import { UnreadMessagesComponent } from './header/unread-messages/unread-messages.component';
import { LastUpdatedSurveyResultsComponent } from './searchResults/lastUpdatedTimestamp/last-updated.component';
import { ParseVideoUrlComponent } from './searchResults/parseVideoUrl/parse-video-url.component';
import { SurveyResponseComponent } from './searchResults/response/response.component';
import { SendMessageComponent } from './sendMessage/send-message.component';
import { ShortUrlComponent } from './shortUrl/short-url.component';
import { ShareButtonsComponent } from './shareButtons/shareButtons.component';
import { ShareStripeComponent } from './shareStripe/shareStripe.component';
import { DonationModalComponent } from './searchResults/donationHelper/donationModal/donation-modal.component';

/**
 * services
 */
import { AuthenticationService } from './login/login.service';
import { UnreadMessagesService } from './header/unread-messages/unread-messages.service';
import { SendMessageService } from './sendMessage/send-message.service';
import { RecentSurveyService } from './home/disasterList/recent-survey.service';
import { SurveyNotificationsService } from './dashboard/servicesComponets/surveyNotifications/surveyNotifications.service';

/**
 * feature, shared modules
 */
import { SharedServicesFeatureModule } from './sharedServices/shared-services.module';
import { LocationMapModule } from './common/locationMap/location-map.module';
import { ScoreByLocationComponent } from './searchResults/scoreModal/scoreByLocationModal/score-by-location.component';
import { ScoreByDisasterComponent } from './searchResults/scoreModal/scoreByDisasterModal/score-by-disaster.component';
import { LoaderModule } from './common/loader/loader.module';
import { GlobalMapComponent } from './global-map/global-map.component';
import { AgmCoreModule } from '@agm/core';

/* router configuration */
const appRoutes: Routes = [
  /* milestone 1 routes: login and helpers */
  { path: '', component: HomeComponent },
  {
    path: 'login',
    canActivate: [CanActivateViaAlreadyLoggedInGuard],
    loadChildren: 'app/login/login.module#LoginFeatureModule',
  },
  { path: 'registration', loadChildren: 'app/registration/registration.module#RegistrationFeatureModule' },
  {
    path: 'registration-success',
    loadChildren: 'app/registration/registration-success.module#RegistrationSuccessFeatureModule',
  },
  { path: 'nominate', loadChildren: 'app/nominate/nominate.module#NominateFeatureModule' },
  { path: 'nominate-success', loadChildren: 'app/nominate/nominate-success.module#NominateSuccessFeatureModule' },
  { path: 'reset', loadChildren: 'app/reset/reset.module#ResetFeatureModule' },
  { path: 'reset-password/:id', loadChildren: 'app/resetPassword/reset-password.module#ResetPasswordFeatureModule' },
  { path: 'volunteers', component: VolunteersPageComponent },
  { path: 'disasters', component: DisastersPageComponent },
  { path: 'privacy-policy', component: PrivacyPolicyPageComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'intro', component: IntroComponent },
  { path: 'global-org-map', component: GlobalMapComponent },
  /* milestone 2 and 3 routes: dashboards */
  {
    path: 'dashboard',
    loadChildren: 'app/dashboard/dashboard.module#DashboardFeatureModule',
    canActivate: [CanActivateViaAuthGuard],
  },

  /* milestone 4 routes: reporting */
  { path: 'organization/:id', component: OrgBackgroundComponent },
  { path: 'disaster/:id', component: DisasterAboutComponent },
  { path: 'disaster/:disasterId/organization/:org_id', component: OrgDisasterComponent },
  { path: 'location/:id', component: LocationAboutComponent },
  { path: 'org-location/:org_id/:loc_id', component: OrgLocationComponent },
  // TODO: component OrgDisasterComponent is mapped to 2 routes
  // below is the one should be in use, please check
  { path: 'org-disaster/:org_id/:disaster_id', component: OrgDisasterComponent },

  { path: 'help/faq/:id', loadChildren: 'app/help/faq/faq.module#FrequentlyAskedQuestionsFeatureModule' },

  /* todo remove these testing routes */
  // {path: 'org-location', component: OrgLocationComponent},
  // {path: 'org-disaster', component: OrgDisasterComponent},
  { path: 'disaster-about', component: DisasterAboutComponent },
  { path: 'location-about', component: LocationAboutComponent },
  { path: 'dap-donation', component: DapDonationComponent },
  { path: 'givelively-donation', component: GiveLivelyDonationComponent },
  { path: 'dap-donation-thanks', component: DapDonationThanksComponent },

  { path: 'subscribe', loadChildren: 'app/newsletter/newsletter-subscribe.module#NewsLetterSubscribeModule' },
  {
    path: 'subscribe-success',
    loadChildren: 'app/newsletter/newsletter-subscribe-success.module#NewsLetterSubscribeSuccessFeatureModule',
  },
  /* default: any route that does not match an above path will redirect to the home page */
  { path: 'page-not-found', loadChildren: 'app/pageNotFound/page-not-found.module#PageNotFoundFeatureModule' },
  { path: '**', component: ShortUrlComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}

/* translate support */
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    /* our app's components imported in the root module*/
    FooterComponent,
    PrivacyPolicyPageComponent,
    VolunteersPageComponent,
    DisastersPageComponent,
    HeaderComponent,
    PickerMenuComponent,
    OptionPickerComponent,
    CurrencyPickerComponent,
    LanguagePickerComponent,
    MarketingComponent,
    SocialNetworkComponent,
    ShareButtonsComponent,
    ShareStripeComponent,
    DonationModalComponent,
    ResponseProfileComponent,
    OrgLocationComponent,
    OrgDisasterComponent,
    OrgBackgroundComponent,
    DisasterAboutComponent,
    DapDonationComponent,
    GiveLivelyDonationComponent,
    DapDonationThanksComponent,

    ShortUrlComponent,

    LastUpdatedSurveyResultsComponent,
    ParseVideoUrlComponent,

    SurveyResponseComponent,

    SearchComponent,
    DisasterListHomeComponent,
    ReportModalComponent,

    LocationAboutComponent,

    HomeComponent,
    AboutUsComponent,
    IntroComponent,
    GlobalMapComponent,
    CarouselComponent,

    ScoreByLocationComponent,
    ScoreByDisasterComponent,
    SendMessageComponent,
    UnreadMessagesComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    SimpleNotificationsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCsG1DwuyKnJYGoLJ-EKCgsyxGK-ghDfBk',
    }),

    RecaptchaModule,

    AppRoutingModule,

    SharedServicesFeatureModule,

    LocationMapModule,

    BrowserAnimationsModule,

    MatTooltipModule,

    LoaderModule,

    ShareButtonsModule.forRoot(),
    MatExpansionModule,

    FontAwesomeModule,
    QuillModule.forRoot(QUILL_CONFIG_TOKEN.ngInjectableDef),
  ],
  providers: [
    AuthenticationService,
    SendMessageService,
    UnreadMessagesService,
    RecentSurveyService,
    SurveyNotificationsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
